import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-form-switch-button',
  standalone: true,
  imports: [],
  templateUrl: './form-switch-button.component.html',
  styleUrl: './form-switch-button.component.scss'
})
export class FormSwitchButtonComponent {
  @Input() text = 'Btn name';
  @Output() switchForm = new EventEmitter<boolean>();

  switch(): void {
    this.switchForm.emit(true);
  }
}
