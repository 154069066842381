export function hexToRgb(hex: string | undefined, opacity = 1): string {
  if (!hex) return 'rgba(255, 255, 255)';

  const h = hex.replace('#', '');
  const isValidHex = /^[0-9A-Fa-f]{6}$/g.test(h);

  if (!isValidHex) return 'rgba(255, 255, 255)';

  const r = parseInt(h.substring(0, 2), 16);
  const g = parseInt(h.substring(2, 4), 16);
  const b = parseInt(h.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}
