import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { map, Observable, tap, combineLatest } from 'rxjs';
import { CreateEditTagComponent } from '../app/settings/components/tags/create-edit-tag/create-edit-tag.component';
import { ManageTagRange } from '../app/settings/components/tags/manage-tags-modal/manage-tag-range.class';
import { environment } from '../environments/environment';
import { EntityType } from '../models/enums/entityType';
import { Tag } from '../models/tag';

@Injectable({ providedIn: 'root' })
export class TagsService {
  tagsLoading = signal<boolean>(false);
  allTags = signal<Tag[]>([]);
  popularTags = signal<Tag[]>([]);

  constructor(
    private api: HttpClient,
    private modalService: NgbModal
  ) {
    this.tagsLoading.set(true);
    combineLatest([this.getTags(), this.getPopularTags()])
      .subscribe(() => this.tagsLoading.set(false));
  }

  public openCreateEditTagModal(): { modalRef: NgbModalRef, instance: CreateEditTagComponent } {
    const modalRef = this.modalService?.open(CreateEditTagComponent,
      {
        windowClass: 'create-edit-tag-modal',
        size: 'lg',
        centered: true
      });

    return { modalRef, instance: modalRef.componentInstance as CreateEditTagComponent };
  }

  public getPopularTags(count = 15): Observable<Tag[]> {
    const options = { params: { count } };
    return this.api.get<Tag[]>(`${ environment.api_url }/tags/popular`, options)
      .pipe(tap((tags) => this.popularTags.set(tags)));
  }

  public createOrUpdateTag(tag: Tag, entityOrIndividualId = '', isAssigned = true, type = EntityType.Company): Observable<string> {
    const options = { observe: 'response', params: { entityOrIndividualId, isAssigned, type } };
    // @ts-ignore
    return this.api.post<string>(`${ environment.api_url }/tags`, tag, options)
      .pipe(map((response) => {
        const tagId = response?.['body'] as string;
        this.allTags.set([...this.allTags(), new Tag({ ...tag, tagId })]);
        return tagId;
      }));
  }

  public assignTag(tag: Tag, entityOrIndividualId: string, isAssigned = false, type = EntityType.Company): Observable<void> {
    const options = { params: { entityOrIndividualId, isAssigned, type } };
    return this.api.post<void>(`${ environment.api_url }/tags/${ tag.tagId }/assign`, tag, options);
  }

  public getTags(): Observable<Tag[]> {
    return this.api.get<Tag[]>(`${ environment.api_url }/tags`)
      .pipe(map((tags) => {
        this.allTags.set(tags.sort((a, b) => a.name.localeCompare(b.name)));
        return this.allTags();
      }));
  }

  public deleteTag(tagId: string): Observable<any> {
    const options = { params: { tagId } };
    return this.api.delete<void>(`${ environment.api_url }/tags`, options)
      .pipe(tap(() => this.allTags.set(this.allTags().filter(tag => tag.tagId !== tagId))));
  }

  public deleteTags(tagsId: string[]): Observable<unknown> {
    const options = { params: { tagsId } };
    return this.api.delete<void>(`${ environment.api_url }/tags`, options)
      .pipe(tap(() => this.allTags.set(this.allTags().filter(tag => !tagsId.includes(tag.tagId)))));
  }

  public assignTagsRange(manageTagRange: ManageTagRange): Observable<null> {
    return this.api.post<null>(`${ environment.api_url }/tags/range`, manageTagRange);
  }
}
