@if(customContent) {
  <ng-content></ng-content>
} @else if(tooltipText) {
  @if(isDisabled) {
    <i class="disabled" [ngClass]="iconClass"></i>
  } @else {
    <i
      container="body"
      [ngClass]="iconClass"
      [tooltipClass]="widthAuto ? 'tooltip-info width-auto' : 'tooltip-info'"
      [placement]="placement"
      [ngbTooltip]="tooltipText"
      (click)="onClick()"
    ></i>
  }
}
