<div class="search" [ngClass]="{ 'hide-border': borderNone, 'active': isActive }" [class.w-100]="fullWidth">
  <i class="fa fa-search" [ngClass]="{'text-gray600': !searchControl.value, 'text-gray700': searchControl.value}"></i>
  <input
    type="text"
    class="search-input"
    #searchInput
    [formControl]="searchControl"
    [placeholder]="placeholder"
    (focus)="onFocus()"
    (blur)="onBlur()"
  >
  @if(searchControl.value) {
    <i class="icon ic-close" (click)="clear()"></i>
  }
</div>
