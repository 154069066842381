import { Component, DestroyRef, ElementRef, Input, OnInit, Optional, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NgControl,
  ReactiveFormsModule
} from "@angular/forms";
import { NgClass } from "@angular/common";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-search-input',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    ReactiveFormsModule,
  ],
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss'
})
export class SearchInputComponent implements OnInit, ControlValueAccessor {
  @ViewChild('searchInput', { static: true }) searchInput!: ElementRef;

  @Input() fullWidth = false;
  @Input() placeholder = 'Search for something...';
  @Input() borderNone = false;
  @Input() searchDelayTime = 1000;
  @Input() autoFocused = false;
  searchControl: FormControl = new FormControl();
  isActive = false;

  constructor(@Optional() protected ngControl: NgControl, private destroyRef: DestroyRef) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(
      debounceTime(this.searchDelayTime),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((value: string) => {
      this.onChange(value);
    });

    if(this.autoFocused) {
      (this.searchInput.nativeElement as HTMLInputElement).focus();
    }
  }

  onFocus(): void {
    this.isActive = true;
  }

  onBlur(): void {
    this.isActive = false;
  }

  clear(): void {
    this.searchControl.setValue('');
    if(this.autoFocused) {
      (this.searchInput.nativeElement as HTMLInputElement).focus();
    }
  }

  onChange = (value: string) => {};
  onTouched = () => {};

  writeValue(value: any): void {
    this.searchControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.searchControl.disable() : this.searchControl.enable();
  }
}
