<div class="input-container" [style.width]="width">
  <div class="header">
    <div class="label">{{ label }}</div>
  </div>

  <div class="input-wrapper">
    <label class="control" ngbDropdown [autoClose]="'outside'">
      <span class="color-template preview" [style.background]="value"></span>
      <input
        ngbDropdownToggle
        type="text"
        class="input m-0"
        value="#FCC60B"
        [disabled]="isDisabled || disabled"
        [placeholder]="placeholder"
        [(ngModel)]="value"
        (blur)="onTouched()"
      >

      <div ngbDropdownMenu class="color-palette-dropdown">
        <ul class="color-palette-list">
          @for (color of colors; track color.value) {
            <li>
              <button
                type="button"
                class="color color-template"
                [title]="color.label"
                [class.selected]="color.value === value"
                [style.background]="color.value"
                (click)="setColorFromPalette(color.value!.toString())"
              ></button>
            </li>
          }
        </ul>
      </div>
    </label>

    <app-button
      title="Generate random color"
      btnColor="white"
      iconClass="ic-refresh ic-md icon"
      [hidden]="hideRandom"
      (clickBtn)="generateRandomColor()"
    ></app-button>
  </div>

  @if (shouldShowErrors && displayValidationError) {
    <app-validation-error [customErrors]="customErrors"></app-validation-error>
  }
</div>

