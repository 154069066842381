import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter, forwardRef,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { NgClass, NgStyle } from "@angular/common";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'app-checkbox',
  standalone: true,
  imports: [
    NgClass,
    NgStyle
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true
    }
  ],
  templateUrl: './checkbox.component.html',
  styleUrl: './checkbox.component.scss'
})
export class CheckboxComponent implements OnInit, AfterViewInit {
  @Input() value = false;
  @Input() color = '';
  @Input() bgColor = '';
  @Input() label = '';
  @Input() labelIconClass = '';
  @Input() helperText = '';
  @Input() roundShape = true;
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() disabled = false;
  @Input() boldLabel = false;
  @Output() change = new EventEmitter<boolean>();

  @ViewChild('input') private input!: ElementRef<HTMLInputElement>;
  @ViewChild('checkbox') private checkbox!: ElementRef<HTMLLabelElement>;

  private onChange: any = (): void => {};
  private onTouched: any = (): void => {};


  @HostListener('click', ['$event'])
  toggleChecked(): void {
    if (this.disabled) {
      return;
    }
    this.value = !this.value;
    this.input.nativeElement.checked = this.value;
    if (this.value && this.bgColor) {
      this.checkbox.nativeElement.style.setProperty('background-color', this.bgColor);
    } else {
      this.checkbox.nativeElement.style.removeProperty('background-color');
    }
    this.change.emit(this.value);
    if (this.onChange) {
      this.onChange(this.value);
    }
    this.onTouched();
  }

  writeValue(value: boolean): void {
    this.value = value;
  }

  registerOnChange(fn: Function): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: Function): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {
    this.value = this.value || false;
  }

  ngAfterViewInit(): void {
    this.input.nativeElement.checked = this.value;
    if (this.bgColor && this.value) {
      this.checkbox.nativeElement.style.backgroundColor = this.bgColor;
    }
  }
}
