import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass } from "@angular/common";

@Component({
  selector: 'app-button',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss'
})
export class ButtonComponent {
  @Input() btnColor: 'orange' | 'white' | 'gray' | 'red-orange' = 'orange';
  @Input() text = '';
  @Input() iconClass = '';
  @Input() iconClassLast = '';
  @Input() iconLast = false;
  @Input() isDisabled = false;
  @Input() loading = false;
  @Output() clickBtn = new EventEmitter<boolean>();

  onClick(): void {
    this.clickBtn.emit(true);
  }
}
