import { FormControl, ValidationErrors } from '@angular/forms';

export function isHexColor(color: string): boolean {
  if (!color)
    return false;

  // supports formats #000, #000000, #00000000
  const hexColorPattern = /^#[0-9A-Fa-f]{3,8}$/i;
  const isCorrectStringLength = [4, 7, 9].includes(color.length);
  return isCorrectStringLength && hexColorPattern.test(color);
}

export function isHexColorValidator(control: FormControl): ValidationErrors | null {
  const controlValue = control?.value?.toString();
  if (!controlValue) {
    return null;
  }

  return isHexColor(controlValue) ? null : { invalidHexColor: true };
}
