import { JsonPipe, NgClass } from '@angular/common';
import { Component, EventEmitter, input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Guid } from '../../../helpers/guid.helper';
import { FormSwitchButtonComponent } from '../form-switch-button/form-switch-button.component';
import { InputComponent } from '../input/input.component';
import { LinkComponent } from '../link/link.component';
import { ValidationErrorComponent } from '../validation-error/validation-error.component';

@Component({
  selector: 'app-textarea',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    LinkComponent,
    ReactiveFormsModule,
    JsonPipe,
    FormSwitchButtonComponent,
    ValidationErrorComponent
  ],
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss', './../input/input.component.scss']
})
export class TextareaComponent extends InputComponent {
  rows = input<number>(2);
  maxTextLength = input<number | null>(null);

  @Output() submit = new EventEmitter<void>();
  @Output() focus = new EventEmitter<void>();

  id = Guid.generate();

  resetHeight(): void {
    const textarea = document.getElementById(this.id);
    if (textarea)
      textarea.style.height = 'auto';
  }

  onSubmit(): void {
    if (this.disabled)
      return;

    this.submit.emit();
  }

  onFocus(): void {
    this.focus.emit();
  }
}
