import { booleanAttribute, Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NgControl, ReactiveFormsModule } from "@angular/forms";
import { JsonPipe, NgClass } from "@angular/common";
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { LinkComponent } from "../link/link.component";
import { FormSwitchButtonComponent } from "../form-switch-button/form-switch-button.component";
import { ValidationErrorComponent } from "../validation-error/validation-error.component";
import { TooltipComponent } from "../tooltip/tooltip.component";

@Component({
  selector: 'app-input',
  standalone: true,
  imports: [
    FormsModule,
    NgClass,
    LinkComponent,
    ReactiveFormsModule,
    JsonPipe,
    FormSwitchButtonComponent,
    ValidationErrorComponent,
    NgbTooltip,
    TooltipComponent
  ],
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss'
})
export class InputComponent implements ControlValueAccessor {
  @Input() width = '100%';
  @Input() placeholder = ''
  @Input() label = ''
  @Input() link = ''
  @Input() text = ''
  @Input() switchBtnText = ''
  @Input() helperText = '';
  @Input() warningTooltipMessage = '';
  @Input() shouldShowErrors = true;
  @Input({ transform: booleanAttribute }) readonly = false;
  @Input({ transform: booleanAttribute }) disabled = false;
  @Input({ transform: booleanAttribute }) isDisabled = false;
  @Input({ transform: booleanAttribute }) validationError = false;
  @Input({ transform: booleanAttribute }) checkedState= false;
  @Input({ transform: booleanAttribute }) warningState = false;
  @Input({ transform: booleanAttribute }) usedInsideGrid = false;
  @Input() customErrors: Record<string, string> = {};
  @Output() switchBtnClick = new EventEmitter<boolean>();

  constructor(@Optional() protected ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }
  }

  #value = '';

  get value(): string {
    return this.#value;
  }

  set value(value: string) {
    this.#value = value;
    this.onChange(this.#value);
  }

  get displayValidationError(): boolean {
    return !!(this.ngControl && this.ngControl.touched && this.ngControl.errors);
  }

  onClickBtn(): void {
    this.switchBtnClick.emit(true);
  }

  onChange: any = (): void => {};
  onTouched: any = (): void => {};

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onKeyPressed(e: KeyboardEvent): void {
    if(this.usedInsideGrid) {
      e.stopPropagation();
    }
  }
}
