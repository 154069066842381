import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from '../button/button.component';
import { DatePickerComponent } from '../date-picker/date-picker.component';
import { DividerComponent } from '../divider/divider.component';
import { InputComponent } from '../input/input.component';
import { SelectComponent } from '../select/select.component';
import { AgActionIconButtonComponent } from "../grid/components/ag-action-icon-button/ag-action-icon-button.component";

@Component({
  selector: 'app-common-modal-wrapper',
  standalone: true,
  imports: [
    ButtonComponent,
    DatePickerComponent,
    DividerComponent,
    FormsModule,
    InputComponent,
    ReactiveFormsModule,
    SelectComponent,
    NgClass,
    AgActionIconButtonComponent
  ],
  templateUrl: './common-modal-wrapper.component.html',
  styleUrl: './common-modal-wrapper.component.scss'
})
export class CommonModalWrapperComponent {
  @Input() isLoading = false;
  @Input() isDisabled = false;
  @Input() icon = 'ic-form';
  @Input() header = '';
  @Input() subheader = '';
  @Input() saveBtnText = 'Save';
  @Input() closeBtnText = 'Cancel';

  @Output() close = new EventEmitter();
  @Output() submitModal = new EventEmitter();

  onClose() {
    this.close.emit();
  }

  onSubmit() {
    this.submitModal.emit();
  }
}
