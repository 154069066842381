import { NgTemplateOutlet } from '@angular/common';
import {
  Component,
  computed,
  effect,
  EventEmitter,
  inject,
  input,
  Input,
  OnInit,
  Optional,
  Output,
  Signal,
  signal,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormRecord,
  FormsModule,
  NgControl,
  ReactiveFormsModule
} from '@angular/forms';
import { NgbDropdown, NgbDropdownItem, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { excludeArray } from '../../../../functions/exclude-array';
import { Tag } from '../../../../models/tag';
import { TagsService } from '../../../../services/tags.service';
import { ITagsEntity } from '../../../settings/components/tags/manage-tags-modal/manage-tags-modal.component';
import { ButtonComponent } from '../../common/button/button.component';
import { CheckboxComponent } from '../../common/checkbox/checkbox.component';
import { DividerComponent } from '../../common/divider/divider.component';
import { LoaderComponent } from '../../common/loader/loader.component';
import { SearchInputComponent } from '../../common/search-input/search-input.component';
import { TagComponent } from '../../common/tag/tag.component';

@Component({
  selector: 'app-select-tags-dropdown',
  standalone: true,
  imports: [
    NgTemplateOutlet,
    ReactiveFormsModule,
    FormsModule,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    ButtonComponent,
    SearchInputComponent,
    DividerComponent,
    LoaderComponent,
    CheckboxComponent,
    TagComponent,
    NgbDropdownItem,
    NgxSkeletonLoaderModule,
  ],
  templateUrl: './select-tags-dropdown.component.html',
  styleUrl: './select-tags-dropdown.component.scss'
})
export class SelectTagsDropdownComponent implements ControlValueAccessor, OnInit {
  private tagsService = inject(TagsService);

  @Input() entityProfile!: ITagsEntity;
  @Input() searchVisible = true;
  @Input() allowAddNewTag = true;
  @Input() disabled = false;
  @Input() notFoundMessage = 'No tag has been assigned';
  @Input() notFoundSuggestedMessage = 'Nothing found...Do you want to add a new tag?'
  showOnlyAllTags = input<boolean>(false);
  tags = input<Tag[]>([]);
  isLoading = this.tagsService.tagsLoading.asReadonly();

  @Output() addNewTag = new EventEmitter<void>();
  @Output() assignTag = new EventEmitter<{ tag: Tag, assign: boolean }>();

  tagsFormRecord = new FormRecord<FormControl<boolean>>({});
  placeholdersWiths = new Array(5).fill('').map(() => (Math.floor(Math.random() * (100 - 25 + 1)) + 25) + 'px');

  #value = signal('');
  allTags = this.tagsService.allTags.asReadonly();
  popularTags = computed(() => excludeArray('tagId', this.tagsService.popularTags.asReadonly()(), this.tags()));
  filteredTags: Signal<Tag[]> = computed<Tag[]>(() => {
    const searchValue = this.#value()?.toLowerCase().trim();

    if (!searchValue) {
      return this.tags();
    }

    return this.tags().filter(tag => tag?.name?.toLowerCase().includes(searchValue));
  });

  filteredSuggestedTags = computed(() => {
    const searchValue = this.#value()?.toLowerCase().trim();

    if (!searchValue) {
      return this.showOnlyAllTags()
        ? this.allTags()
        : this.popularTags();
    }

    return this.allTags().filter(tag => tag?.name?.toLowerCase().includes(searchValue));
  });

  constructor(@Optional() protected ngControl: NgControl) {
    if (ngControl) {
      ngControl.valueAccessor = this;
    }

    effect(() => {
      this.allTags().forEach((tag) => {
        if (!this.tagsFormRecord.controls[tag.tagId]) {
          this.setTagFormRecordControl(tag.tagId, false);
        }
      });
      this.updateTagsFormRecordValues();
    });

    effect(() => {
      this.popularTags().forEach((tag) => this.setTagFormRecordControl(tag.tagId, false));
      this.updateTagsFormRecordValues();
    });

    effect(() => {
      this.tags().forEach((tag) => this.setTagFormRecordControl(tag.tagId));
      this.updateTagsFormRecordValues();

      if (!this.tags().length) {
        Object.values(this.tagsFormRecord.controls).forEach(control => control.setValue(false));
      }
    });
  }

  updateTagsFormRecordValues(): void {
    this.tags().forEach((tag) => {
      this.tagsFormRecord.controls[tag.tagId]?.setValue(true);
    });
  }

  ngOnInit() {
    this.updateNoAssignedTagsMessage();
  }

  onAddNewTag(): void {
    if (!this.allowAddNewTag)
      return;

    this.addNewTag.emit();
  }

  onAssignTag(tag: Tag, assign: boolean): void {
    this.assignTag.emit({ tag, assign });
    this.tagsFormRecord.controls[tag.tagId]?.setValue(assign);
  }

  private setTagFormRecordControl(tagId: string, checked = true): void {
    this.tagsFormRecord.setControl(tagId, new FormControl<boolean>(checked, { nonNullable: true }));
  }

  private updateNoAssignedTagsMessage(): void {
    if (this.entityProfile?.['companyType'] !== undefined) {
      // The entity is company
      this.notFoundMessage += ' to this company ';
    } else if (this.entityProfile?.['trustType'] !== undefined) {
      // The entity is trust
      this.notFoundMessage += ' to this trust';
    } else if (this.entityProfile?.['individualId'] !== undefined) {
      // The entity is individual
      this.notFoundMessage += ' to this individual';
    }
  }

  get value(): string {
    return this.#value();
  }

  set value(value: string) {
    this.#value.set(value);
    this.onChange(this.#value);
  }

  onChange: any = (): void => {
  };
  onTouched: any = (): void => {
  };

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  get entityId(): string {
    return this.entityProfile?.['entityId'] || this.entityProfile['individualId'];
  }
}
