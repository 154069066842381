@if (readonly && value) {
  <div class="input-container" [style.width]="width">
    <div class="header">
      <div class="label-readonly">{{ label }}</div>
    </div>

    <textarea
      class="input-readonly"
      [id]="id"
      [disabled]=true
      [(ngModel)]="value"
      [placeholder]="placeholder"
      [rows]="rows()"
      [maxlength]="maxTextLength()"
    ></textarea>
  </div>

} @else {
  <div class="input-container" [style.width]="width">
    <div class="header" [class.no-label]="!label">
      @if (label) {
        <div class="label">{{ label }}</div>
      }

      @if (link && text) {
        <div>
          <app-link [link]="link" [text]="text"></app-link>
        </div>
      } @else {
        <app-form-switch-button
          [text]="switchBtnText"
          (switchForm)="onClickBtn()"
        ></app-form-switch-button>
      }
    </div>

    <div
      class="input-wrapper"
      [ngClass]="{'error': validationError || displayValidationError, 'check': checkedState, 'warn': warningState}"
    >
      <textarea
        class="input"
        [id]="id"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [(ngModel)]="value"
        [ngModelOptions]="{standalone: true}"
        (keydown.control.enter)="onSubmit()"
        (blur)="onTouched()"
        (focus)="onFocus()"
        [rows]="rows()"
        [maxlength]="maxTextLength()"
      ></textarea>

      <i
        class="input-icon icon"
        [ngClass]="{'ic-circle-info': validationError || warningState || displayValidationError, 'ic-circle-check': checkedState}">
      </i>
    </div>

    @if (helperText) {
      <div class="helper" [ngClass]="{'error': validationError}">{{ helperText }}</div>
    }

    @if (shouldShowErrors && displayValidationError) {
      <app-validation-error [customErrors]="customErrors"></app-validation-error>
    }
  </div>
}

