import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class DatepickerHelper {
  static buildDateString(date: Date): Date {
    date = new Date(date);
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return `${ date.getFullYear() }-${
      month > 9 ? month : ('0' + month)
    }-${
      day > 9 ? day : ('0' + day)
    }` as unknown as Date;
  }

  static getStructFromDate(date: Date): NgbDateStruct {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  static getStructFromDateDayAfter(date: Date): NgbDateStruct {
    const day = date.getDate() + 1;
    const updatedDate = new Date(date);
    updatedDate.setDate(day);
    return {
      year: updatedDate.getFullYear(),
      month: updatedDate.getMonth() + 1,
      day: updatedDate.getDate()
    };
  }

  static getStructFromDateOrNull(date: Date | null): NgbDateStruct | null {
    if (!date)
      return null;

    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  static getDateFromStruct(dateStruct: NgbDateStruct): Date {
    return new Date(dateStruct.year, dateStruct.month - 1, dateStruct.day, 12, 0, 0, 0);
  }

  static getStructFromString(date: string): NgbDateStruct {
    return DatepickerHelper.getStructFromDate(new Date(date));
  }

  static getToday() {
    const today = new Date();
    today.setHours(12, 0, 0, 0);
    return today;
  }

  static getYesterday() {
    const today = DatepickerHelper.getToday();
    today.setDate(today.getDate() - 1);
    return today;
  }

  static getTodayStruct(): NgbDateStruct {
    return DatepickerHelper.getStructFromDate(DatepickerHelper.getToday());
  }

  static getNextYearStruct(): NgbDateStruct {
    const nextYearStruct = DatepickerHelper.getStructFromDate(DatepickerHelper.getToday());
    nextYearStruct.year++;
    return nextYearStruct;
  }

  static getNextNYearsStruct(years: number): NgbDateStruct {
    const nextYearStruct = DatepickerHelper.getStructFromDate(DatepickerHelper.getToday());
    nextYearStruct.year += years;
    return nextYearStruct;
  }

  static getNextNMonthStruct(n: number): NgbDateStruct {
    const today = DatepickerHelper.getToday()
    today.setMonth(today.getMonth() + n);
    return DatepickerHelper.getStructFromDate(today);
  }

  static getYesterdayStruct(): NgbDateStruct {
    return DatepickerHelper.getStructFromDate(DatepickerHelper.getYesterday());
  }

  static getMatureDate(): Date {
    const today = DatepickerHelper.getToday();
    return new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());
  }

  static getMatureDateStruct(): NgbDateStruct {
    return DatepickerHelper.getStructFromDate(DatepickerHelper.getMatureDate());
  }

  static buildTodayDateString(): Date {
    return DatepickerHelper.buildDateString(DatepickerHelper.getToday());
  }

  static getDefaultMinDateStruct(): NgbDateStruct {
    const today = DatepickerHelper.getToday();
    return { year: today.getFullYear() - 100, month: today.getMonth() + 1, day: today.getDate() };
  }

  static getDefaultMaxDateStruct(): NgbDateStruct {
    return this.getNextNMonthStruct(2);
  }

  static getDateOfEstablishmentMinDate(date: string): NgbDateStruct {
    const dateOfEstablishment = DatepickerHelper.toDate(date);
    dateOfEstablishment.setDate(dateOfEstablishment.getDate() + 1);
    return DatepickerHelper.getStructFromDate(dateOfEstablishment);
  }

  static getDateWithDaysAfterToday(days: number): Date {
    return new Date(DatepickerHelper.getToday().getTime() + days * 24 * 60 * 60 * 1000);
  }

  static getDateWithDaysAfterTodayStruct(days: number): NgbDateStruct {
    return DatepickerHelper.getStructFromDate(DatepickerHelper.getDateWithDaysAfterToday(days));
  }

  static toString(dateOrString: Date | string | null | undefined): string {
    if (!dateOrString) {
      return '';
    }

    let date: Date;

    if (typeof dateOrString === 'string') {
      date = new Date(dateOrString);
    } else if (dateOrString instanceof Date) {
      date = dateOrString;
    } else {
      throw new Error('Invalid input. Expected string or Date object.');
    }

    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();

    return `${ day }/${ month }/${ year }`;
  }

  static toDate(dateOrString: Date | string | undefined | null): Date {
    if (!dateOrString) {
      return new Date();
    }

    let date: Date;

    if (typeof dateOrString === 'string') {
      date = new Date(dateOrString);
      date.setHours(12, 0, 0, 0);
    } else if (dateOrString instanceof Date) {
      date = new Date(dateOrString);
    } else {
      date = new Date();
    }

    return date;
  }

  static getDateOf1990January(): Date {
    return new Date(1990, 0, 1);
  }
}

export class DatePickerFormValidators {
  static tooSmallDateIsDateOfEstablishmentErrorMessageMessage = 'Please enter a date that is after the company registration date.';
  static errorMessages = {
    required: 'Date is required',
    dateInvalid: 'Date is invalid',
    ngbDate: 'Date is invalid',
    tooSmallDate: 'The date cannot be less than the minimum date',
    tooBigDate: 'The date cannot be greater than the maximum date',
    tooYoung: 'The date cannot be less than 18 years',
    tooOld: 'The date cannot be greater than 100 years',
    strangeDays: `Invalid days. [] days are bigger then 31.`,
    strangeMonths: `Invalid months. [] months are bigger then 12.`
  };
  static errorMessagesWithDateOfEstablishmentErrorMessages = {
    ...DatePickerFormValidators.errorMessages,
    tooSmallDate: DatePickerFormValidators.tooSmallDateIsDateOfEstablishmentErrorMessageMessage
  };

  static buildDateString(date: Date): Date {
    return DatepickerHelper.buildDateString(date);
  }

  static dateFormatValidator(control: AbstractControl<NgbDateStruct | Date | null>): ValidationErrors | null {
    const value = control.value;

    if (!value) {
      return null;
    }

    if (value instanceof Date) {
      return null;
    }

    const date = `${ value?.day }/${ value?.month }/${ value?.year }`;

    const regex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;
    return regex.test(date) ? null : { 'dateInvalid': { value } };
  }

  static dateBetweenValidator(min: NgbDateStruct, max: NgbDateStruct = {
    year: 2100,
    month: 11,
    day: 31
  }): ValidatorFn {
    return (control: AbstractControl<NgbDateStruct | null>) => {
      const value = control.value;

      if (!value) {
        return { 'required': { value } };
      }

      const currentDate = new Date(value.year, value.month - 1, value.day);
      const minDate = new Date(min.year, min.month - 1, min.day);
      const maxDate = new Date(max.year, max.month - 1, max.day);

      if (minDate > maxDate) {
        throw new Error('Bad rules for [dateBetweenValidator] datepicker validator!');
      }

      if (minDate >= currentDate) {
        return { tooSmallDate: 'The date cannot be less than the current date.' };
      }

      if (maxDate <= currentDate) {
        return { tooBigDate: 'The date cannot be greater than the current date.' };
      }

      return null;
    };
  }

  static ngbDateStructBetweenValidator(min: NgbDateStruct, max: NgbDateStruct = {
    year: 2100,
    month: 11,
    day: 31
  }): ValidatorFn {
    return (control: AbstractControl<NgbDateStruct | null>) => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const minDate = new Date(min.year, min.month - 1, min.day);
      const maxDate = new Date(max.year, max.month - 1, max.day + 1);
      const currentDate = DatepickerHelper.getDateFromStruct(value);

      if (minDate > maxDate) {
        throw new Error('Bad rules for [ngbDateStructBetweenValidator] datepicker validator!');
      }

      if (value.day > 31) {
        return { strangeDays: value.day };
      }

      if (value.month > 12) {
        return { strangeMonths: value.month };
      }

      if (minDate > currentDate) {
        return { tooSmallDate: 'The date cannot be less than the current date.' };
      }

      if (maxDate < currentDate) {
        return { tooBigDate: 'The date cannot be greater than the current date.' };
      }

      return null;
    };
  }

  static dateDateBetweenValidator(min: NgbDateStruct, max: NgbDateStruct = {
    year: 2100,
    month: 11,
    day: 31
  }): ValidatorFn {
    const minDate = new Date(min.year, min.month - 1, min.day);
    const maxDate = new Date(max.year, max.month - 1, max.day);

    return (control: AbstractControl<Date | null>) => {
      const value = control.value;

      if (!value) {
        return { 'required': { value } };
      }

      if (minDate > maxDate) {
        throw new Error('Bad rules for [dateDateBetweenValidator] datepicker validator!');
      }

      if (minDate > value) {
        return { tooSmallDate: 'The date cannot be less than the current date.' };
      }

      if (maxDate < value) {
        return { tooBigDate: 'The date cannot be greater than the current date.' };
      }

      return null;
    };
  }

  static matureAgeValidator(control: AbstractControl<NgbDateStruct | null>) {
    const value = control.value;

    if (!value) {
      return { 'required': { value } };
    }

    const currentDate = new Date(value.year, value.month - 1, value.day);
    const minDate = new Date(currentDate.getFullYear() - 18, currentDate.getMonth(), currentDate.getDate());
    const maxDate = new Date(currentDate.getFullYear() - 100, currentDate.getMonth(), currentDate.getDate());

    if (currentDate > minDate) {
      return { tooYoung: true };
    }

    if (currentDate < maxDate) {
      return { tooOld: true };
    }

    return null;
  }
}
