import { Guid } from '../app/helpers/guid.helper';

export class Tag {
  organisationId: string | null;
  tagId: string;
  name: string;
  description: string;
  customColor?: string;
  class: string;
  isSystemTag: boolean;

  constructor(data: Partial<Tag> = {}) {
    this.organisationId = data.organisationId ?? Guid.EmptyGuid;
    this.tagId = data.tagId ?? Guid.EmptyGuid;
    this.name = data.name ?? '';
    this.description = data.description ?? '';
    this.customColor = data.customColor ?? '';
    this.class = data.class ?? '';
    this.isSystemTag = data.isSystemTag ?? false;
  }
}
