@if(readonly && value) {
  <div class="input-container" [style.width]="width">
    <div class="header">
      <div class="label-readonly">{{label}}</div>
    </div>

    <input
      type="text"
      class="input-readonly"
      [disabled] = true
      [(ngModel)]="value"
      [placeholder]="placeholder"
    >
  </div>

} @else {
  <div class="input-container" [style.width]="width">
    <div class="header">
      @if(label) {
        <div class="label">{{label}}</div>
      }

      @if(link && text) {
        <div><app-link [link]="link" [text]="text"></app-link></div>
      } @else {
        <app-form-switch-button
          [text]="switchBtnText"
          (switchForm)="onClickBtn()"
        ></app-form-switch-button>
      }
    </div>

    <div
      class="input-wrapper"
      [ngClass]="{'error': validationError || displayValidationError, 'check': checkedState, 'warn': warningState}"
    >
      <input
        type="text"
        class="input"
        [disabled]="isDisabled || disabled"
        [placeholder]="placeholder"
        [(ngModel)]="value"
        (keydown)="onKeyPressed($event)"
        (blur)="onTouched()"
      >

      <app-tooltip [customContent]="true">
        <i
          container="body"
          class="input-icon icon"
          placement="top"
          [ngbTooltip]="warningTooltipMessage"
          [tooltipClass]="'tooltip-info width-auto'"
          [ngClass]="{'ic-circle-info': validationError || warningState || displayValidationError, 'ic-circle-check': checkedState}">
        </i>
      </app-tooltip>
    </div>

    @if(helperText) {
      <div class="helper" [ngClass]="{'error': validationError}">{{helperText}}</div>
    }

    @if(shouldShowErrors && displayValidationError) {
      <app-validation-error [customErrors]="customErrors"></app-validation-error>
    }
  </div>
}

