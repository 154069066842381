<div>
  <label
    #checkbox
    class="checkbox"
    [ngClass]="{
      'checked': value,
      'disabled': disabled,
      'round': roundShape,
      'square': !roundShape,
      'small': size === 'small',
      'medium': size === 'medium'
    }"
    (click)="$event.preventDefault()"
  >
    <input #input class="value-holder" type="checkbox" [value]="value" [disabled]="disabled" />
    <i class="icon-check fa fa-check" [ngStyle]="{ color: color }"></i>
  </label>

  @if(label) {
    @if(labelIconClass) {
      <i class="ml-2" [class]="labelIconClass"></i>
      <span class="label" [ngClass]="{ 'disabled': disabled, 'bold': boldLabel }">{{ label }}</span>
    } @else {
      <span class="label ml-2" [ngClass]="{ 'disabled': disabled, 'bold': boldLabel }">{{ label }}</span>
    }

    @if(helperText) {
      <br>
      <span class="helper-text" [ngClass]="{ 'disabled': disabled }">{{ helperText }}</span>
    }
  }
</div>
