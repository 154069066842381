@if (isLoading()) {
  <div class="tags-block">
    <ul class="tags-list">
      @for (width of placeholdersWiths; track width) {
        <ng-container *ngTemplateOutlet="tagPlaceholder; context: { width }"></ng-container>
      }
    </ul>
  </div>
} @else {
  <div class="customize-tags-dropdown">
    @if (searchVisible) {
      <div class="search">
        <app-search-input
          placeholder="Filter Tags"
          [fullWidth]="true"
          [borderNone]="true"
          [searchDelayTime]="0"
          [(ngModel)]="value"
        ></app-search-input>
      </div>

      <app-divider></app-divider>
    }

    <div class="tags-block">
      @if (!showOnlyAllTags()) {
        <div class="actual-tags">
          <ul class="tags-list">
            @for (tag of filteredTags(); track tag.tagId) {
              <ng-container *ngTemplateOutlet="tagItem; context: { tag }"></ng-container>
            }
          </ul>

          @if (!tags().length && !showOnlyAllTags() && filteredSuggestedTags().length) {
            <div class="no-data">{{ notFoundMessage }}</div>
          }
        </div>
      }

      <div class="suggested-tags">
        @if (!showOnlyAllTags() && filteredSuggestedTags().length) {
          <div class="suggestions-title">Suggestions</div>
        }

        <ul class="tags-list">
          @for (tag of filteredSuggestedTags(); track tag.tagId) {
            <ng-container *ngTemplateOutlet="tagItem; context: { tag }"></ng-container>
          }
        </ul>
      </div>

      @if (!filteredTags().length && !filteredSuggestedTags().length) {
        <div class="no-data">{{ notFoundSuggestedMessage }}</div>
      }
    </div>

    @if (allowAddNewTag) {
      <app-divider></app-divider>

      <div class="add-new-tag-btn-wrapper">
        <app-button
          ngbDropdownItem
          class="p-0"
          text="Add New Tag"
          btnColor="white"
          (clickBtn)="onAddNewTag()"
        ></app-button>
      </div>
    }
  </div>
}

<ng-template #tagItem let-tag="tag">
  <li class="tag-item">
    <label>
      <div class="checkbox-tag">
        @if (tagsFormRecord.controls[tag.tagId]) {
          <app-checkbox
            [formControl]="tagsFormRecord.controls[tag.tagId]"
            (change)="onAssignTag(tag, $event)"
          ></app-checkbox>
        }

        <app-tag [tag]="tag"></app-tag>
      </div>

      <div class="tag-description" [hidden]="!tag?.description">
        {{ tag?.description }}
      </div>
    </label>
  </li>
</ng-template>

<ng-template #tagPlaceholder let-width="width">
  <li class="tag-item tag-placeholder">
    <label>
      <div class="checkbox-tag">
        <app-checkbox [disabled]="true"></app-checkbox>
        <ngx-skeleton-loader count="1" appearance="line" [style.width]="width"/>
      </div>

      <div class="tag-description">
        <ngx-skeleton-loader style="height: 16px;" count="1" appearance="line"/>
      </div>
    </label>
  </li>
</ng-template>
