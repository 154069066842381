import { NgClass } from '@angular/common';
import { Component, Input, Optional } from '@angular/core';
import { FormsModule, NgControl, ReactiveFormsModule, } from '@angular/forms';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { ButtonComponent } from '../button/button.component';
import { FormSwitchButtonComponent } from '../form-switch-button/form-switch-button.component';
import { InputComponent } from '../input/input.component';
import { LinkComponent } from '../link/link.component';
import { ValidationErrorComponent } from '../validation-error/validation-error.component';
import { TagsColorsSelectOptions } from '../../../settings/components/tags/tag-colors.contant';

@Component({
  selector: 'app-color-picker',
  standalone: true,
  imports: [
    FormSwitchButtonComponent,
    LinkComponent,
    ReactiveFormsModule,
    ValidationErrorComponent,
    FormsModule,
    NgClass,
    ButtonComponent,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu
  ],
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.scss', '../input/input.component.scss']
})
export class ColorPickerComponent extends InputComponent {
  @Input() override label = 'Color';
  @Input() override customErrors = { invalidHexColor: 'Invalid hex color' };
  @Input() colors = TagsColorsSelectOptions;
  @Input() fullWidth = false;
  @Input() hideRandom = false;

  constructor(@Optional() override ngControl: NgControl) {
    super(ngControl);
  }

  generateRandomColor(): void {
    if (!this.colors.length) return;
    const randomIndex = Math.floor(Math.random() * TagsColorsSelectOptions.length);
    this.value = TagsColorsSelectOptions[randomIndex].value?.toString() ?? '#FCC60B';
    this.onChange(this.value);
  }

  setColorFromPalette(color: string): void {
    this.value = color;
  }

  override writeValue(value: string | null): void {
    if (value)
      this.value = value;
    else if (!this.hideRandom)
      this.generateRandomColor();
  }
}
