<div
  class="tag"
  [ngStyle]="{
    '--tag-color': customColor,
    '--tag-bg-color': backgroundColor,
  }"
  (click)="onClickTag()"
>
  <span>{{ tag.name }}</span>
  <ng-content />
</div>
