export enum EntityType {
  Company,
  Trust,
  Individual,
}

export function getEntityType(entity: any): EntityType {
  if (entity?.companyType !== undefined) {
    // The entity is company
    return EntityType.Company;
  } else if (entity?.trustType !== undefined) {
    // The entity is trust
    return EntityType.Trust;
  } else if (entity?.individualId !== undefined) {
    // The entity is individual
    return EntityType.Individual;
  }

  return EntityType.Company;
}
