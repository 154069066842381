import { Component, Input } from '@angular/core';
import { NgClass } from "@angular/common";

@Component({
  selector: 'app-divider',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './divider.component.html',
  styleUrl: './divider.component.scss'
})
export class DividerComponent {
  @Input() vertical = false;
  @Input() margin = '';
}
