import { Component, inject, Input } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { KeyValuePipe } from '@angular/common';
import { ReplacePipe } from '../../../../pipes/replace.pipe';

@Component({
  selector: 'app-validation-error',
  standalone: true,
  imports: [
    KeyValuePipe,
    ReplacePipe
  ],
  templateUrl: './validation-error.component.html',
  styleUrl: './validation-error.component.scss'
})
export class ValidationErrorComponent {
  ngControl = inject(NgControl, { optional: true });

  @Input() control?: AbstractControl;
  @Input() set customErrors(value: Record<string, string>) {
    this.errors = { ...this.errors, ...value };
  }

  errors = {
    required: 'Field is required.',
    streetInvalid: 'Enter the correct street.',
    suburbInvalid: 'Enter the correct suburb.',
    postCodeInvalid: 'Enter the correct Postcode.',
    acn: 'Invalid ACN.',
    companyName: 'Company name does not seem to be valid.',
    maxLength: 'The value is too long.',
    maxlength: 'The value is too long.',
    minLength: 'The value is too short.',
    minlength: 'The value is too short.',
    email: 'Enter a valid email address to proceed.'
  };
}
