<div class="common-modal">
  <div class="modal-header">
    <div class="header-container">

      <div class="title-wrapper">
        <div class="top">
          @if (icon) {
            <i class="ic-form ic-md icon" [ngClass]="icon"></i>
          }
          <h2 class="title" [title]="header">{{ header }}</h2>
        </div>

        <p class="sub-heading" [title]="subheader">{{ subheader }}</p>
      </div>

      <app-ag-action-icon-button
        iconClass="ic-xs ic-close"
        (action)="onClose()"
      ></app-ag-action-icon-button>
    </div>
  </div>

  <form (ngSubmit)="$event.preventDefault();onSubmit()" class="modal-body">
    <ng-content></ng-content>
    <button type="submit" hidden></button>
  </form>

  <div class="modal-footer">
    <app-button [text]="saveBtnText" [isDisabled]="isLoading || isDisabled" (clickBtn)="onSubmit()"></app-button>
    <app-button btnColor="white" [isDisabled]="isLoading" [text]="closeBtnText" (clickBtn)="onClose()"></app-button>
  </div>
</div>
