<div class="select-container" [style.width]="width">
  <div class="header">
    @if(label) {
      <div class="label">{{label}}</div>
    }

    @if(link && text) {
      <div><app-link [link]="link" [text]="text"></app-link></div>
    }
  </div>

  @if (isDisabled) {
    <div class="dropdown">
      <button
        type="button"
        class="input-wrapper input"
        [ngClass]="{'opened': dropdownOpen}"
        [disabled]="isDisabled || disabled"
      >
        @if(value) {
          <div class="text" [title]="value.label">{{value.label}}</div>
        } @else {
          <div class="select-placeholder">{{placeholder}}</div>
        }
        <span class="icon ic-sm ic-select-icon"></span>
      </button>
    </div>
  } @else {
    <div ngbDropdown class="dropdown" (openChange)="getDropdownState($event)">
      <button
        type="button"
        class="input-wrapper input"
        [ngClass]="{'opened': dropdownOpen}"
        [disabled]="isDisabled || disabled"
        ngbDropdownToggle
      >
        @if(value) {
          <div class="text" [title]="value.label">{{value.label}}</div>
        } @else {
          <div class="select-placeholder">{{placeholder}}</div>
        }
        <span class="icon ic-sm ic-select-icon"></span>
      </button>
      <div ngbDropdownMenu class="dropdown-menu">
        @if (options.length) {
          @for(option of sortedOptions; track $index) {
            <button
              ngbDropdownItem
              type="button"
              class="dropdown-item"
              [class.selected]="option.label === value?.label && option.value === value?.value"
              [disabled]="isDisabled || disabled"
              (click)="selectOption(option)"
            >
              <div class="dropdown-button">
                <span>{{ option.label }}</span>
                <span class="icon ic-sm ic-circle-check"></span>
              </div>
            </button>
          }
        } @else {
          <div class="no-options">{{ noOptionsText }}</div>
        }
      </div>
    </div>
  }

  @if(helperText) {
    <div class="helper">{{helperText}}</div>
  }

  @if(displayValidationError) {
    <app-validation-error></app-validation-error>
  }
</div>
