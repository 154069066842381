<app-common-modal-wrapper
  [header]="isEdit ? 'Edit Tag' : 'Create Tag'"
  [saveBtnText]="isEdit ? 'Edit' : 'Add'"
  [formGroup]="form"
  (close)="onModalClose()"
  (submitModal)="onModalSubmit()"
>
  <div class="form-group controls">
    <app-input
      label="Tag Name"
      placeholder="Tag Name"
      formControlName="name"
      [customErrors]="tagNameCustomErrors"
    ></app-input>

    <app-color-picker formControlName="customColor"></app-color-picker>
  </div>

  <div>
    <app-textarea
      label="Description"
      formControlName="description"
      placeholder="Description (optional)"
    ></app-textarea>
  </div>

  <app-divider [margin]="'24px 0'"></app-divider>

  <div class="tag-preview">
    <app-tag [tag]="viewTag" [hidden]="!form.controls.name.value"></app-tag>
  </div>
</app-common-modal-wrapper>
