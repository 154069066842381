import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Tag } from '../../../../../models/tag';
import { TagsService } from '../../../../../services/tags.service';
import { isHexColorValidator } from '../../../../../validators/hex-color-code.validator';
import { ColorPickerComponent } from '../../../../components/common/color-picker/color-picker.component';
import {
  CommonModalWrapperComponent
} from '../../../../components/common/common-modal-wrapper/common-modal-wrapper.component';
import { DividerComponent } from '../../../../components/common/divider/divider.component';
import { InputComponent } from '../../../../components/common/input/input.component';
import { TagComponent } from '../../../../components/common/tag/tag.component';
import { TextareaComponent } from '../../../../components/common/textarea/textarea.component';
import { CustomFormValidators } from "../../../../../custom-form-validators/custom-form-validators";

@Component({
  selector: 'app-create-edit-tag',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    DividerComponent,
    CommonModalWrapperComponent,
    InputComponent,
    TextareaComponent,
    ColorPickerComponent,
    TagComponent,
  ],
  templateUrl: './create-edit-tag.component.html',
  styleUrl: './create-edit-tag.component.scss'
})
export class CreateEditTagComponent implements OnInit, AfterViewInit {
  activeModal = inject(NgbActiveModal);
  cdr = inject(ChangeDetectorRef);
  tagsService = inject(TagsService);

  @Input() tag = new Tag();
  @Input() isEdit = false;

  tagNameCustomErrors = {
    forbidExistingTagName: 'Tags with identical names already exist. Kindly opt for a unique name or select from the current tags.',
  };
  viewTag = new Tag();
  tagsForValidation = signal<Tag[]>([]);
  form = new FormGroup({
    name: new FormControl<string | null>(null, [Validators.required, CustomFormValidators.maxLength(50)]),
    description: new FormControl<string | null>(null, [CustomFormValidators.maxLength(200)]),
    customColor: new FormControl<string | null>(null, [Validators.required, isHexColorValidator]),
  });

  constructor() {
    this.form.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe(({ name, customColor }) => {
        this.viewTag.name = name ?? '';
        this.viewTag.customColor = customColor ?? '';
      });
  }

  ngOnInit() {
    this.viewTag = new Tag(this.tag);
    this.tagsForValidation.set(this.tagsService.allTags().filter((tag) => tag.tagId !== this.tag.tagId));
    this.addForbidExistingTagNameValidator();
  }

  ngAfterViewInit(): void {
    if (this.tag.tagId) {
      this.form.patchValue(this.tag);
    }

    this.cdr.detectChanges();
  }

  onModalClose(): void {
    this.activeModal.dismiss();
  }

  onModalSubmit(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.activeModal.close(new Tag({ ...this.tag, ...this.viewTag, ...this.form.value as Partial<Tag> }));
  }

  private addForbidExistingTagNameValidator(): void {
    this.form.controls.name.addValidators((nameControl) => {
      const name = (nameControl.value ?? '')?.trim()?.toLowerCase();
      const existingTag = this.tagsForValidation()
        .find((tag) => {
          return tag.name.trim().toLowerCase() === name;
        });
      return existingTag ? { forbidExistingTagName: true } : null;
    });
  }
}
