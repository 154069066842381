import { NgClass, NgStyle } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Tag } from '../../../../models/tag';
import { hexToRgb } from '../../../helpers/hex-to-rgb.helper';

@Component({
  selector: 'app-tag',
  standalone: true,
  imports: [
    NgStyle,
    NgClass
  ],
  templateUrl: './tag.component.html',
  styleUrl: './tag.component.scss'
})
export class TagComponent implements ICellRendererAngularComp {
  @Input() tag = new Tag();

  @Output() clickTag = new EventEmitter<Tag>();

  onClickTag(): void {
    this.clickTag.emit(this.tag);
  }

  agInit(params: ICellRendererParams): void {
    this.tag = new Tag(params?.data || {});
  }

  refresh(params: ICellRendererParams): boolean {
    this.tag = new Tag(params?.data || {});
    return true;
  }

  get customColor(): string {
    return this.tag.customColor || '#000';
  }

  get backgroundColor(): string {
    return hexToRgb(this.tag.customColor, 0.1);
  }
}
