import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replace',
  standalone: true
})
export class ReplacePipe implements PipeTransform {
  readonly pattern = /\[.*?\]/g;

  // replace brackets [] in string with args
  transform(str: string, replacement: string | number): string {
    return str.replace(this.pattern, replacement.toString());
  }
}
