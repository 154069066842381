<div class="datepicker input-container" [class.small-width]="smallWidth">
  <div class="header">
    @if (label) {
      <div class="label">{{ label }}</div>
    }
  </div>

  <div class="input-wrapper">
    <input
      tabindex="-1"
      #datepicker="ngbDatepicker"
      ngbDatepicker
      class="input hidden"
      placeholder="dd/MM/yyyy"
      name="dp"
      [minDate]="minDate"
      [maxDate]="maxDate"
      [footerTemplate]="addTodayButtonFooter"
      [ngClass]="{'error': validationError || displayValidationError, 'check': checkedState, 'warn': warningState}"
      [formControl]="datepickerControl"
      (dateSelect)="onDateSelect($event)"
      (blur)="onTouched()"
    />

    <input
      type="text"
      class="input"
      [placeholder]="placeholder"
      [(ngModel)]="value"
      [disabled]="disabled"
      (input)="onInput($event)"
      (blur)="onBlurInput()"
    />

    <i
      class="input-icon icon"
      [ngClass]="{'ic-circle-info': validationError || warningState || displayValidationError, 'ic-circle-check': checkedState}">
    </i>

    <button
      class="calendar-switch"
      type="button"
      [disabled]="disabled"
      (click)="datepicker.toggle()"
    >
      <i class="icon ic-calendar"></i>
    </button>

    <ng-template #addTodayButtonFooter>
      @if (showTodayButton && shouldSetTodayButton) {
        <div>
          <button
            class="today-btn"
            type="button"
            [disabled]="disabled"
            (click)="setTodaysDate(); datepicker.close()"
          >
            Today
          </button>
        </div>
      }
    </ng-template>
  </div>

  @if (helperText) {
    <div class="helper" [ngClass]="{'error': validationError}">{{ helperText }}</div>
  }

  @if (shouldShowErrors && displayValidationError) {
    <app-validation-error [customErrors]="customErrors"></app-validation-error>
  }
</div>

