<button
  class="ag-action-icon-button"
  type="button"
  placement="top"
  container="body"
  tooltipClass="tooltip-info"
  [ngbTooltip]="tooltipText"
  [disableTooltip]="!tooltipText || disabled"
  [disabled]="disabled"
  (click)="onAction($event)"
>
  <i [class]="'icon ' + iconClass"></i>
  @if (numberOfAlerts) {
    <span class="ml-1">{{ numberOfAlerts }}</span>
  }
</button>
