<button
  class="btn"
  type="button"
  [ngClass]="{
    'btn-primary': btnColor === 'orange',
    'btn-secondary': btnColor === 'white',
    'btn-gray': btnColor === 'gray',
    'btn-resolve': btnColor === 'red-orange',
    'custom-padding': iconClass && !text
  }"
  [disabled]="isDisabled"
  (click)="onClick()"
>
  @if (iconLast) {
    <div class="d-flex justify-content-center align-items-center">
      <span class="button-text">{{ text }}</span>
      @if(loading){
        <i class="fad fa-spinner-third fa-spin ml-2"></i>
      }
      <span [ngClass]="{ 'ml-2': iconClass }" [class]="iconClass"></span>
    </div>
  } @else {
    <div class="d-flex justify-content-center align-items-center">
      <span [class]="iconClass"></span>
      <span class="button-text" [ngClass]="{ 'ml-2': iconClass && text, 'mr-2': iconClassLast && text }">{{ text }}</span>
      @if(loading){
        <i class="fad fa-spinner-third fa-spin"></i>
      }
      <span [class]="iconClassLast"></span>
    </div>
  }
</button>
