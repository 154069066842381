import { SelectOption } from '../../../../models/selectOptions';

export const TagsColorsDictionary: Record<string, string> = {
  'Kashmir Blue': '#5E6F87',
  'Shuttle Gray': '#5F6776',
  'Sandstone': '#766C65',
  'Coral Red': '#EC4545',
  'Pumpkin': '#F56E11',
  'Fire Bush': '#EF9908',
  'Tulip Tree': '#ECB200',
  'Christi': '#67B100',
  'Emerald': '#13BD51',
  'Green Haze': '#079F6D',
  'Keppel': '#11B19F',
  'Pelorous': '#01B2D0',
  'Curious Blue': '#099BDC',
  'Azure Radiance': '#327BF3',
  'Royal Blue': '#575BEA',
  'Fuchsia Blue': '#8452F5',
  'Amethyst': '#A453F2',
  'Fuchsia Pink': '#D244E7',
  'Cerise': '#E44493',
  'Radical Red': '#FE3E5F'
};

export const TagsColorsSelectOptions: SelectOption[] = Object.entries(TagsColorsDictionary)
  .map(([label, value]) => ({ value, label }));
