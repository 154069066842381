import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { Trust } from '../../../../../../models/trust';
import { NgbTooltip } from "@ng-bootstrap/ng-bootstrap";

type ActionEvent = (data: unknown, event: MouseEvent) => void;

type CellRendererParams = ICellRendererParams<Trust> & {
  action: ActionEvent,
  disabled?: boolean
};

@Component({
  selector: 'app-ag-action-icon-button',
  standalone: true,
  templateUrl: './ag-action-icon-button.component.html',
  imports: [NgbTooltip],
  styleUrl: './ag-action-icon-button.component.scss'
})
export class AgActionIconButtonComponent implements ICellRendererAngularComp {
  @Input() numberOfAlerts = 0;
  @Input() iconClass = '';
  @Input() tooltipText = '';
  @Input() disabled = false;

  @Output() action = new EventEmitter<MouseEvent>();

  params!: CellRendererParams;

  agInit(params: CellRendererParams): void {
    this.params = params;
    this.disabled = params.disabled ?? false;
  }

  refresh(params: CellRendererParams): boolean {
    this.params = params;
    this.disabled = params.disabled ?? false;
    return true;
  }

  onAction(event: MouseEvent): void {
    if (this.disabled)
      return;

    this.params?.action(this.params?.data, event);
    this.action.emit(event);
  }
}
